.file-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 15px;
    grid-auto-flow: dense; /* Allow items to fit tightly */
    width:80%;
    margin: auto;
    margin-top:5vw;
  }
  
  .file-item {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .gallery-image {
    background-color: blueviolet;
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: transform 0.3s ease;
    border-radius: 8px;
  }
  
  .file-item.landscape {
    grid-column: span 2; /* Makes landscape images wider */
    aspect-ratio: 16 / 9; /* Approximate landscape aspect ratio */
  }
  
  .file-item.portrait {
    grid-row: span 2; /* Makes portrait images taller */
    aspect-ratio: 9 / 16; /* Approximate portrait aspect ratio */
  }

  .uploaded-by {
    position: absolute; /* Positioning text at the bottom of the image */
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    margin:0;
    color: black; /* Text color */
    text-align: center; /* Center the text */
    z-index: 1; /* Ensure text is above other elements */
    background: linear-gradient(to top, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0)); /* Gradient background */
  }
  
  .delete-button {
    position: absolute;
    top: 5px; /* Adjust to desired position */
    right: 5px; /* Adjust to desired position */
    background-color: #e74c3c; /* Red color for delete button */
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    z-index: 2; /* Ensure it appears above other elements */
  }
  
  .delete-button:hover {
    background-color: #c0392b; /* Darker red on hover */
  }
  