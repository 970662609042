.App {
  text-align: center;
}

.content-wrapper {
  padding-top: 5vw; /* Space below the navbar */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  min-height: 100vh; /* Minimum height to fit the viewport */
  min-width: 100vw; /* Minimum width to fit the viewport */
  box-sizing: border-box; /* Ensures padding doesn't affect min-height or min-width */
}

#uploadSection {
  margin-top: 23px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 10px;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.navbargrad {
  background-image: linear-gradient(white, #a87dc2e0);
  margin: 0;
  padding:1vw;
}

#background {
  background-color: #A87DC2;
}

.radialgrad {
  background-image: radial-gradient(farthest-side, white,#A87DC2);
}

#navbar {
  list-style-type: none

}

nav {
  position: fixed;
  width:100%;
  z-index: 1;
  
}

.NavButton {

  list-style-type: none;
}


#MainLogo {
width:90%;
margin-top: auto;
}

#logoButton {
  width:15%;

}

#MainLogo:hover {
  width:100%;
  transition: ease-in-out 200ms;
}

body {
  font-family: 'Roboto';
  font-weight: 1;
  overflow-x:hidden;
}

#verticalline {
  width:5px;
  background-image: linear-gradient(90deg, rgb(255,255,255,0), rgb(51, 51, 51), rgb(255,255,255,0));
  height:100%;
  position: absolute;
  left:50%;
  top:0;
  bottom:0;
  z-index:-1;
  
}

#scrollcontainer {
  position: absolute;
  top:100;
  width:100%;
  margin-top:10vh;
}

#maingrid {
  display: grid;
  grid-template-columns: 40% 20% 40%;
  width:100%;
  color:rgb(51, 51, 51);
  margin-bottom: 10vw;

}

#msgbutton {
  color:black;
  transition: color ease-in-out 300ms ;
}

#msgbutton:hover {
  color:#A87DC2;
  transition: color ease-in-out 300ms ;

}


.shakebutton {
  text-align: center;
  cursor: pointer;
}

.shakebutton:hover {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  
  30%, 50%, 70% {
    transform: translate3d(-2px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(2px, 0, 0);
}

}

@media screen and (max-width: 1100px) {
  #maingrid {
      grid-template-columns: 100%;
  }

  #logoButton {
      width:30%;
  }

  .content-wrapper {
    padding-top: 10vw; /* Space below the navbar */
  }
}

@media screen and (max-width: 700px) {
  #logoButton {
      width:40%;
  }


}

.polaroidgrid {
  grid-template-rows: 85% 15%;
}

.justmeagaindownhere {
  font-family: 'Just Me Again Down Here', cursive;
}

.polaroidright {
  grid-column-start: 3;
  transform: rotate(5deg);
}

.polaroidleft {
  grid-column-start: 1;
  transform: rotate(-7deg);
}

.yearmarker {
  grid-column-start: 2;
}

.polaroidgrid {
  font-size: 3rem;
  
}

.polaroid {
  object-fit: cover;
  height: fit-content;
  margin-top: 5px;
}

@media screen and (max-width: 1100px) {
  .polaroidright {
      grid-column-start: 1;
      transform: rotate(0);
  }

  .polaroidleft {
      grid-column-start: 1;
      transform: rotate(0);
  }

  .yearmarker {
      grid-column-start: 1;
      font-size:medium
  }

  .polaroidgrid {
      font-size: 2rem;
      
  }

  .polaroidinfo {
      flex-direction: column;
  }

  .polaroidinfo h2 {
      margin: auto
  }

  #duckarea {
      flex-direction: column;
      margin: auto;
      overflow:scroll;
  }

  .duckdiv {
      margin: auto;
  }


}


@media screen and (max-width: 700px) {
  #counter, #andsomuchmore, #weddingcounter, #dailymessage {
      font-size:2.5rem;
      padding-left:50px;
      padding-right:50px;

  }

  #scrollcontainer {
      top:50;
      margin-top:7vh;
  }

  .yearmarker {
      grid-column-start: 1;
      font-size:large
  }

}

#password-parent {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center it horizontally and vertically */
  height: auto; /* Adjust height as needed */
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}



.password-container {
  background-color: #353b48;
  border-radius: 30px;
  padding: 10px;
  width: 50vw;
  height:5vw;
  box-shadow: 0 19px 38px rgba(0, 0, 0, .10), 0 15px 12px rgba(0, 0, 0, .15);
  display: flex;
  align-items: center;
}

.password_input {
  color: white;
  border: 0;
  outline: 0;
  background: none;
  line-height: 40px;
  transition: width .4s linear;
  padding: 0 10px;
  width:100%;
  font-weight: 300;
  font-size:large;
  caret-color: #A87DC2;
}

#enter_icon {
  background-color: rgb(0,0,0,0);
  color:white;
  padding: 0.5rem;
  padding-right:1rem;
  width:20%;
}

.enter_icon:hover {
  color:white;
}

#enter_icon i {
  width:100%;
}


@media screen and (max-width: 900px) {
  .password-container {
      width: 80%;
      height:15vw;
  }

  #enter_icon {
      font-size:10px;
    }


}

@media screen and (min-width: 900px) {
  .password-container {
      width:50%;

  }

  #enter_icon {
      font-size:15px;
    }


}


.duckdiv { 
  background: #bfc20093;

}

.FileInput {
  width:70%;
  margin-left: auto;
  margin-right: auto;

}

.buttonGroup {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: fit-content;
  gap:2vw;
  margin: auto;
}




