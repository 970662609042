.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8); /* Dark background with transparency */
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 90vw;
    max-height: 90vh;
    margin: auto;
    border-radius: 2vw;
    z-index: 1000; /* Make sure it's above everything else */
  }
  
  .popup-content {
    position: relative;
    width: 100%;
    height:100%;
    margin:auto;
    display: flex;
  }
  
  .popup-image {
    max-width: 90%; /* Limit the width of the image */
    max-height: 90%; /* Limit the height of the image */
    border-radius: 8px;
    margin: auto;
    object-fit: contain;
  }
  
  .close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: #e74c3c; /* Red color for close button */
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .close-button:hover {
    background-color: #c0392b; /* Darker red on hover */
  }
  